<template>
  <div class="salarieRegister" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Créer votre compte</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- Déjà connecté -->

            <div v-if="isAuthenticated">
              <h2 class="text-center">
                Vous êtes déjà connecté.
              </h2>
              <div class="row text-center">
                <div class="col-12">
                  <contactComponent />
                </div>
              </div>

              <b-button
                block variant="outline-primary" class="mt-4"
                @click='goToHome'>
                Retour à l'accueil
              </b-button>
            </div>

            <div v-else>

              <div v-if="!isCreated">
                <h2>
                  Créer votre compte pour accéder à votre tableau de bord
                </h2>

                <!-- ERROR MODAL -->

                <b-modal ref="salarieCreationAPIErrorModal" hide-footer title="Erreur lors la création de votre compte">
                  <div class="text-center">
                    <p>Une erreur s'est produite lors de la création de votre compte, veuillez vérifier les données renseignées ou nous
                    contacter via notre <router-link to="/contact"> formulaire de contact</router-link>
                    si cette erreur persiste </p>
                    <b-button class="mt-3" variant="outline-success" @click="hideSalarieCreationAPIErrorModal">Fermer</b-button>
                  </div>
                </b-modal>

                <div>
                  <b-form>
                    <div class="row">
                      <div class="col-sm-6">
                        <b-form-group id="new-salarie-first_name-group" label="Prénom" label-for="new-salarie-first_name-input">
                          <b-form-input
                            v-model="firstName" type="text"
                            maxlength="30"
                            placeholder="Prénom"
                            id="new-salarie-first_name-input"
                            @input="$v.firstName.$touch()"
                            :state="$v.firstName.$dirty ? !$v.firstName.$error : null">
                          </b-form-input>
                          <div class="form-error" v-if="$v.firstName.$error">Votre prénom doit comporter entre 2 et 30 caractères</div>
                        </b-form-group>
                      </div>
                      <div class="col-sm-6">
                        <b-form-group id="new-salarie-last_name-group" label="Nom" label-for="new-salarie-last_name-input">
                          <b-form-input
                            v-model="lastName" type="text"
                            maxlength="150"
                            placeholder="Nom"
                            id="new-salarie-last_name-input"
                            @input="$v.lastName.$touch()"
                            :state="$v.lastName.$dirty ? !$v.lastName.$error : null">
                          </b-form-input>
                          <div class="form-error" v-if="$v.lastName.$error">Votre nom doit comporter entre 2 et 150 caractères</div>
                        </b-form-group>
                      </div>
                    </div>

                    <b-form-group id="new-salarie-username-group" label="Votre email" label-for="new-salarie-username-input">
                      <b-form-input
                        v-model="username" type="email"
                        maxlength="150"
                        placeholder="Email"
                        id="new-salarie-username-input"
                        @input="$v.username.$touch()"
                        :state="$v.username.$dirty ? !$v.username.$error : null">
                      </b-form-input>
                      <small class="text-info"> Il s'agira de votre identifiant pour vous connecter à votre compte </small>
                      <div class="form-error" v-if="!$v.username.email">Veuillez entrer un email au bon format.</div>
                      <small class="text-danger" v-if="isExistingUserUsername"> {{ username }} existe déjà. Veuillez choisir un autre nom d'utilisateur</small>
                    </b-form-group>

                    <b-form-group id="new-salarie-password-group" label="Votre mot de passe" label-for="new-salarie-password-input">
                      <b-form-input
                        v-model="password" type="password"
                        maxlength="249"
                        placeholder="Mot de passe"
                        id="new-salarie-password-input"
                        @input="$v.password.$touch()"
                        :state="$v.password.$dirty ? !$v.password.$error : null">
                      </b-form-input>
                      <div class="form-error" v-if="!$v.password.password_validation">Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.</div>
                    </b-form-group>

                    <b-form-group id="new-salarie-confirm-password-group" label="Confirmez votre mot de passe" label-for="new-salarie-confirm-password-input">
                      <b-form-input
                        v-model="confirmPassword" type="password"
                        maxlength="249"
                        placeholder="Mot de passe"
                        id="new-salarie-confirm-password-input"
                        @input="$v.confirmPassword.$touch()"
                        :state="$v.confirmPassword.$dirty ? !$v.confirmPassword.$error : null">
                      </b-form-input>
                      <div class="form-error" v-if="!$v.confirmPassword.sameAsPassword">Veuillez rentrer deux mots de passe identiques.</div>
                    </b-form-group>


                    <div class="row">
                      <div class="col-sm-6">
                        <b-form-group id="new-salarie-birthdate-group" label="Date de naissance" label-for="new-salarie-birthdate-input">
                          <b-form-input
                            v-model="birthdate" type="date"
                            @input="$v.birthdate.$touch()"
                            :state="$v.birthdate.$dirty ? !$v.birthdate.$error : null">
                          </b-form-input>
                          <div v-if="$browserDetect.isIE || $browserDetect.isSafari" class="text-info">
                            <small>Vous utilisez Internet Explorer ou Safari, veuillez entrer votre
                            date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                          </div>
                          <div class="form-error" v-if="!$v.birthdate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</div>
                        </b-form-group>
                      </div>
                      <div class="col-sm-6">
                        <b-form-group id="new-salarie-phone-number-group" label="Votre numéro de téléphone" label-for="new-salarie-phone-number-input">
                          <b-form-input
                            id="new-salarie-phone-number-input"
                            type="tel" maxlength="10"
                            v-model.trim.lazy="phoneNumber"
                            @input="$v.phoneNumber.$touch()"
                            :state="$v.phoneNumber.$dirty ? !$v.phoneNumber.$error : null"
                            placeholder="Entrez votre numéro de téléphone">
                          </b-form-input>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6">
                        <b-form-group id="new-salarie-professional_category-group" label="Catégorie professionnelle" label-for="new-salarie-professional_category-input">
                          <b-form-select
                            v-model="professionalCategory"
                            id="new-salarie-professional_category-input"
                            @input="$v.professionalCategory.$touch()"
                            :state="$v.professionalCategory.$dirty ? !$v.professionalCategory.$error : null"
                            :options='professionalCategoryArrayForm'>
                          </b-form-select>
                        </b-form-group>
                      </div>
                      <div class="col-sm-6">
                        <b-form-group id="new-salarie-employeur-group" label="Votre employeur actuel" label-for="new-salarie-employeur-input">
                          <b-form-input
                            id="new-salarie-employeur-input"
                            type="text" maxlength="50"
                            v-model="employeur"
                            @input="$v.employeur.$touch()"
                            :state="$v.employeur.$dirty ? !$v.employeur.$error : null"
                            placeholder="Nom de votre employeur">
                          </b-form-input>
                        </b-form-group>
                      </div>
                    </div>

                    <b-form-group id="new-salarie-muna-group" label="Votre numéro de Muna, si connu (facultatif)" label-for="new-salarie-muna-input">
                      <b-form-input
                        id="new-salarie-muna-input"
                        type="text" maxlength="11"
                        v-model.trim.lazy="muna"
                        @input="$v.muna.$touch()"
                        :state="$v.muna.$dirty ? !$v.muna.$error : null"
                        placeholder="XXXXXXXX.XX">
                      </b-form-input>
                      <small class="text-info">Votre Muna comporte 8 chiffres, 1 point et 2 chiffres (XXXXXXXX.XX)</small>
                    </b-form-group>



                    <b-form-group id="new-salarie-has_accepted_conditions-politique-group" label-for="new-salarie-has_accepted_conditions-politique-input">
                      <b-form-checkbox
                        v-model="hasAcceptedConditionsPolitique"
                        id="new-salarie-has_accepted_conditions-politique-input">
                        En cochant cette case, je certifie avoir lu et accepté les
                        <router-link to='/conditions-utilisations' target="_blank">Conditions Générales d’Utilisation</router-link>
                        et la <router-link to='/politique-de-confidentialite' target="_blank">Politique de Confidentialité </router-link>
                      </b-form-checkbox>
                    </b-form-group>

                    <b-button
                      block :variant="createSalarieButtonStyle" class="mt-2"
                      @click='createSalarie'
                      :disabled="$v.firstName.$invalid || $v.lastName.$invalid || $v.username.$invalid || $v.password.$invalid || $v.confirmPassword.$invalid || $v.birthdate.$invalid || $v.phoneNumber.$invalid || $v.professionalCategory.$invalid || $v.employeur.$invalid || $v.muna.$invalid || !this.hasAcceptedConditionsPolitique">
                        <b-spinner small label="Create Salarie Spinner" v-if="createSalarieInProcess"></b-spinner> {{ createSalarieButtonText }}
                    </b-button>
                  </b-form>
                </div>
              </div>


              <!-- Compte créé -->

              <div v-if="isCreated">
                <h2 class="text-center">
                  Votre compte a bien été créé.
                </h2>
                <p>
                  Vous pouvez dès maintenant vous connecter et accéder à votre tableau de bord.
                </p>

                <b-button
                  block variant="outline-primary" class="mt-2 btn-lg"
                  @click='goToLogin'>
                  Je me connecte
                </b-button>

              </div>

              <hr>

              <div class="row text-center">
                <div class="col-12">
                  <contactComponent />
                </div>
              </div>

            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, sameAs, integer} from 'vuelidate/lib/validators'
import { APIDisconnectedService } from '@/api/APIDisconnected'
import contactComponent from '@/components/contactComponent'
import { password_validation, isDate, isMuna } from '@/validators/validators'
import formTitleComponent from '@/components/formTitleComponent'

const apiDisconnectedService = new APIDisconnectedService()

export default {
  name: 'SalarieRegister',
  components: {
    contactComponent,
    formTitleComponent
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      isCreated: false, // false : Compte en cours de création ; true : Compte créé

      firstName: '',
      lastName: '',
      username: '',
      password: '',
      confirmPassword: '',
      birthdate: '',
      phoneNumber: '',
      professionalCategory: 'SAL',
      employeur: '',
      muna: '',
      hasAcceptedConditionsPolitique: false,

      professionalCategoryArrayForm: [
        { text: 'Salarié permanent', value: 'SAL' },
        { text: 'Artiste et technicien du spectacle', value: 'ART' },
        { text: 'Indemnisé Pôle Emploi', value: 'POL' },
        { text: 'Autres', value: 'AUT' },
      ],

      createSalarieButtonText: 'Créer votre compte',
      createSalarieButtonStyle: 'primary',
      createSalarieInProcess: false,

      isExistingUserUsername: false
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
  },
  validations: {
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    username: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required,
      email
    },
    password: {
      maxLength: maxLength(250),
      required,
      password_validation
    },
    confirmPassword: {
      maxLength: maxLength(250),
      required,
      sameAsPassword: sameAs('password'),
    },
    birthdate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    phoneNumber: {
      required,
      integer,
      minLength: minLength(10),
      maxLength: maxLength(16)
    },
    professionalCategory: {
      required,
    },
    employeur: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(50)
    },
    muna: {
      isMuna,
    },
  },


  methods: {
    createSalarie () {
      this.createSalarieInProcess = true
      this.createSalarieButtonText = "Création en cours"
      this.createSalarieButtonStyle = 'secondary'
      apiDisconnectedService.createNewSalarie(
        this.username, this.password, this.confirmPassword, this.firstName, this.lastName,
        this.birthdate, this.phoneNumber, this.professionalCategory, this.employeur, this.muna,
        this.hasAcceptedConditionsPolitique)
      .then(() => {
        this.isCreated = true
      })
      .catch((error) => {
        if (error.response && error.response.data.username && error.response.data.username[0] === "Un utilisateur avec cet email existe déjà") {
          this.isExistingUserUsername = true
        }
        else {
          this.$refs.salarieCreationAPIErrorModal.show()
        }
      })
      .finally(() => {
        this.createSalarieInProcess = false
        this.createSalarieButtonText = "Créer votre compte"
        this.createSalarieButtonStyle = 'primary'
      })

    },
    hideSalarieCreationAPIErrorModal () {
      this.$refs.salarieCreationAPIErrorModal.hide()
    },
    goToHome () {
      this.$router.push('/')
    },
    goToLogin () {
      this.$router.push('/login')
    },
  }
}
</script>
