import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

export class APIDisconnectedService {

  createNewSalarie (username, password, confirm_password, first_name, last_name,
    birthdate, phone_number, professional_category, employeur,
    muna, has_accepted_conditions_politique) {
    const url = apiBaseUrl + `/create-salarie/`
    var headers = {
      'Content-Type': 'application/json',
    }
    return axios.post(
      url,
      {
        'username': username,
        'password': password,
        'confirm_password': confirm_password,
        'first_name': first_name,
        'last_name': last_name,
        'birthdate': birthdate,
        'phone_number': phone_number,
        'professional_category': professional_category,
        'employeur': employeur,
        'muna': muna,
        'has_accepted_conditions_politique': has_accepted_conditions_politique
      },
      {
        'headers': headers,
      }
    )
  }


  sendContactMail (first_name, last_name, email, phone_number,message) {
    const url = apiBaseUrl + `/send-contact-mail/`
    var headers = {
      'Content-Type': 'application/json',
    }
    return axios.post(
      url,
      {
        'first_name': first_name,
        'last_name': last_name,
        'email': email,
        'phone_number': phone_number,
        'message': message,
      },
      {
        'headers': headers,
      }
    )
  }
}
